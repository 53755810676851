import logo from './logo.svg';
import './App.css';
import Sidebar from './compoents/Sidebar';
import Header from './compoents/Header';
import RightSideBar from './compoents/RightSideBar';
import {Routes,Route,BrowserRouter, Navigate, useSearchParams} from 'react-router-dom';
import Home from './Pages/Home';
import Applicants from './Pages/Applicants';
import CourseList from './Pages/CourseList';
import CourseAdd from './Pages/CourseAdd';
import Category from './Pages/Category';
import SubCategory from './Pages/SubCategory';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {maxios} from './store/maxios';
import CourseEdit from './Pages/CourseEdit';
import Login from './Pages/Login';


function App() {
  const loggedIn= useSelector(state=>state.auth.logged_in);
  const dispatch= useDispatch();
  const token = localStorage.getItem('token');
  const loadData = async () => {
    try {
        const response = await maxios.get('/get-data');
        const response2= await maxios.get('/user');
        if(response2.data){
          // localStorage.removeItem('token'); 
          dispatch({type:'SET_USER',payload:response2.data})
          dispatch({type:'SET_LOGIN'})
        }else{
          // localStorage.setItem('token',response2.data.token);
          
          dispatch({type:'SET_LOGOUT'})
          
        }
      
        console.log(response.data);
        dispatch({ type: 'SET_DATA', payload: response.data });
    } catch (e) {
        console.log(e);
        // localStorage.removeItem('token');
    }
};
const loginn= useSelector(state=>state.auth.logged_in);
  useEffect(()=>{
  loadData()
  
  
  },[token])
  return (
     <>
     <BrowserRouter>
     <div className="pre-loader">
			<div className="pre-loader-box">
				<div className="loader-logo">
					<img src="vendors/images/deskapp-logo.svg" alt="" />
				</div>
				<div className="loader-progress" id="progress_div">
					<div className="bar" id="bar1"></div>
				</div>
				<div className="percent" id="percent1">0%</div>
				<div className="loading-text">Loading...</div>
			</div>
		</div>

		<Header/>

		<RightSideBar/>
    <Sidebar/>
		<div className="mobile-menu-overlay"></div>
  
		<Routes>
      <Route path="/" element={!loggedIn? <Navigate to="/login"/>  :<Home/>}/>
      <Route path="/applicants" element={!loggedIn? <Navigate to="/login"/>  :<Applicants/>}/>
      <Route path="/courses/list" element={!loggedIn? <Navigate to="/login"/>  :<CourseList/>}/>
      <Route path="/courses/add" element={!loggedIn? <Navigate to="/login"/>  :<CourseAdd/>}/>
      <Route path="/courses/edit/:id" element={!loggedIn? <Navigate to="/login"/>  :<CourseEdit/>} />

      <Route path="/categories" element={!loggedIn? <Navigate to="/login"/>  :<Category/>}/>
      <Route path="/subcategories" element={!loggedIn? <Navigate to="/login"/>  :<SubCategory/>}/>
      <Route path='/login' element={loggedIn? <Navigate to="/" />: <Login/>}/>
    </Routes>
		
    </BrowserRouter>
		
     </>
  );
}

export default App;
