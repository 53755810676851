import axios from 'axios';
import { useDispatch } from 'react-redux';

// Create an Axios instance
export const maxios = axios.create({
    baseURL: 'https://robotics.acetians.in/api/v2',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add a request interceptor to dynamically set the Authorization header
maxios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Optional: Add a response interceptor for handling responses or errors globally
maxios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Handle unauthorized access (e.g., redirect to login or refresh token)
            console.error('Unauthorized! Redirecting to login.');
        }
        return Promise.reject(error);
    }
);
