import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

function Login() {
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    const login = async () => {
        try {
            if(username==''||password==''){
                alert("Please Enter Userid and password")
                return;
            }
            const response = await axios.post('https://robotics.acetians.in/api/v2/login', {
                username,
                password
            });
            if(response.data.response==true){
                alert(response.data.message); 
                dispatch({type:'SET_LOGIN'});
                 localStorage.setItem('token',response.data.token)
            }else{
                alert(response.data.message);
            }
            

            // Dispatch login action with relevant data
            //  dispatch({ type: 'SET_LOGIN', payload: response.data });

            // Optionally handle storing token or redirecting user after login
            // localStorage.setItem('token', response.data.token); // If you have a token
        } catch (error) {
            console.error('Login failed:', error);
            alert('Login failed. Please check your credentials.');
        }
    }

    return (
        <div className='login-container'>
            <div className='login-box'>
                <form method='post' onSubmit={(e) => e.preventDefault()}>
                    <div className='form-group mb-2'>
                        <label>Username</label>
                        <input
                            type='text'
                            className='form-control'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className='form-group mb-5' style={{ paddingBottom: '50px' }}>
                        <label>Password</label>
                        <input
                            type='password'
                            className='form-control'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button type="button" className='btn btn-success btn-block' onClick={login}>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Login;
