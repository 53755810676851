
const initialState={
    data:{
        'categories':[],
        'subcategories':[],
        'courses':[]
    },
    logged_in:false,
    user:{},
}

const rootReducer= (state=initialState,action) => {
 switch(action.type){
    case 'SET_DATA':
        console.log(action.payload)
        return {...state, data:action.payload};
    case 'SET_LOGIN':
        return {...state, logged_in:true};
    case 'SET_LOGOUT':
        return {...state, logged_in:false};
    case 'SET_USER':
        return {...state , user:action.payload}
    default:
        return state
 }
}

export default rootReducer