import React from 'react'

function Applicants() {
    return (
        <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20">
            <div className="title pb-20">
                <h2 className="h3 mb-0">Applicants</h2>
            </div>
            <div className='p-5'>
                <h1 style={{textAlign:'center'}}>No Applicants Currently</h1>
            </div>
      
            <div className="footer-wrap pd-20 mb-20 card-box">
                Ace Robotics Admin
            </div>
        </div>
        </div> 
      )
}

export default Applicants