import React from 'react';
import { navlinks } from './navlinks';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Sidebar() {
  const loggedIn= useSelector(state=>state.auth.logged_in);
  return (
    <div className="left-side-bar">
      <div className="brand-logo">
        <a href="#">
          <h3>Ace Robot </h3>
           
        </a>
        <div className="close-sidebar" data-toggle="left-sidebar-close">
          <i className="ion-close-round"></i>
        </div>
      </div>
      <div className="menu-block customscroll">
        <div className="sidebar-menu">
          <ul id="accordion-menu">
            {navlinks.map((nav, index) => (
              <li key={index} className="dropdown">
                {nav.sublinks.length > 0 ? (
                  <a href="javascript:;" className="dropdown-toggle">
                    <span className={nav.icon}></span>
                    <span className="mtext">{nav.title}</span>
                  </a>
                ) : (
                  <Link to={nav.link} className="dropdown-toggle">
                    <span className={nav.icon}></span>
                    <span className="mtext">{nav.title}</span>
                  </Link>
                )}
                
                {nav.sublinks.length > 0 && (
                  <ul className="submenu">
                    {nav.sublinks.map((sublink, subIndex) => (
                      <li key={subIndex}>
                        <Link to={sublink.link}>{sublink.subtitle}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
