import React from 'react'
import { useSelector } from 'react-redux'
import CourseCard from '../compoents/CourseCard'

function CourseList() {
  const data= useSelector(state=>state.auth.data)
  return (
    <div className="main-container">
    <div className="xs-pd-20-10 pd-ltr-20" >
        <div className="title pb-20">
            <h2 className="h3 mb-0">Courses List</h2>
        </div>
        
        <div className="row clearfix" style={{minHeight:'90vh'}}>
          {data.courses.map((course)=>{
                return <CourseCard courseData={course}/>
          })}
						
						 
					</div>
  
        <div className="footer-wrap pd-20 mb-20 card-box">
            Ace Robotics Admin
        </div>
    </div>
    </div> 
  )
}

export default CourseList