import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { maxios } from '../store/maxios';

function Category() {
    const data = useSelector(state => state.auth.data);
    const dispatch = useDispatch();
    
    const [categoryName, setCategoryName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [message, setMessage] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [editCategoryId, setEditCategoryId] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    
	const refreshData=()=>{
		setEditCategoryId(null);
		setIsEditMode(false)
		setShowAddModal(true);
		setMessage('Category saved successfully!');
                setCategoryName('');
                setCategoryDescription('');
	}
    const loadData = async () => {
        try {
            const response = await maxios.get('/get-data');
            dispatch({ type: 'SET_DATA', payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };

    const handleEdit = (category) => {
        setCategoryName(category.name);
        setCategoryDescription(category.description);
        setEditCategoryId(category.id);
        setIsEditMode(true);
        setShowEditModal(true); // Open edit modal
    };
	

    const handleDelete = async (categoryId) => {
        if (window.confirm('Are you sure you want to delete this category?')) {
            try {
                const res=await maxios.post(`/categories/delete/${categoryId}`);
                 
				console.log(res); 
				if(res.data.response==true){
					loadData();
					// Refresh data after deletion
				} 
				
                alert(res.data.message);
            } catch (error) {
                console.error('Error deleting category:', error);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const url = isEditMode ? `/categories/edit/${editCategoryId}` : '/categories/add';
        const method = isEditMode ? 'put' : 'post';

        try {
            const response = await maxios[method](url, {
                name: categoryName,
                description: categoryDescription,
            });

            if (response.data.response) {
                setMessage('Category saved successfully!');
                setCategoryName('');
                setCategoryDescription('');
                loadData();
                setIsEditMode(false);
                setShowAddModal(false); // Close add modal
                setShowEditModal(false); // Close edit modal
            } else {
                setMessage('Error: ' + response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const errors = error.response.data.errors;
                if (errors) {
                    const errorMessages = Object.values(errors).flat().join(', ');
                    setMessage(errorMessages);
                } else {
                    setMessage('Error saving category. Please try again.');
                }
            } else {
                setMessage('Error saving category. Please try again.');
            }
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <div className="main-container">
                <div className="xs-pd-20-10 pd-ltr-20">
                    <div className="title pb-20">
                        <h2 className="h3 mb-0">Categories</h2>
                    </div>

                    <div className="pd-20 card-box mb-30">
                        <div className="clearfix mb-20">
                            <div className="pull-left">
                                <h4 className="text-blue h4">Course Categories</h4>
                                <p>Add course categories, edit and delete them.</p>
                            </div>
                            <div className="pull-right">
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>refreshData()} // Open add modal
                                >
                                    <i className="fa fa-plus"></i> Add Category
                                </button>
                            </div>
                        </div>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Category Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Created At</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.categories.map((cat, index) => (
                                    <tr key={cat.id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{cat.name}</td>
                                        <td>{cat.description}</td>
                                        <td>{new Date(cat.created_at).toLocaleDateString()}</td>
                                        <td>
                                            <button onClick={() => handleEdit(cat)} className="btn btn-warning btn-sm">
                                                Edit
                                            </button>
                                            <button onClick={() => handleDelete(cat.id)} className="btn btn-danger btn-sm ml-2">
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="footer-wrap pd-20 mb-20 card-box">
                        Ace Robotics Admin
                    </div>
                </div>
            </div>

            {/* Add Category Modal */}
            {showAddModal && (
                <div className="modal fade show" style={{ display: 'block' }} role="dialog" aria-labelledby="addModalLabel" aria-hidden="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="addModalLabel">Add Category</h4>
                                <button type="button" className="close" onClick={() => setShowAddModal(false)} aria-hidden="true">
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Category Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={categoryName}
                                            onChange={(e) => setCategoryName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea
                                            className="form-control"
                                            value={categoryDescription}
                                            onChange={(e) => setCategoryDescription(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </form>
                                {message && <div className="mt-3">{message}</div>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Category Modal */}
            {showEditModal && (
                <div className="modal fade show" style={{ display: 'block' }} role="dialog" aria-labelledby="editModalLabel" aria-hidden="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="editModalLabel">Edit Category</h4>
                                <button type="button" className="close" onClick={() => setShowEditModal(false)} aria-hidden="true">
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Category Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={categoryName}
                                            onChange={(e) => setCategoryName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea
                                            className="form-control"
                                            value={categoryDescription}
                                            onChange={(e) => setCategoryDescription(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Update</button>
                                </form>
                                {message && <div className="mt-3">{message}</div>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Category;
