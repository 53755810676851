export const navlinks = [
    {
        'title': 'Dashboard',
        'link': '/',
        'icon':'micon bi bi-house',
        'sublinks': []
    },
    {   
        'title': 'Courses',
        'link': '/courses',
        'icon':'micon bi bi-file-earmark-text',
        'sublinks': [
            {
                'subtitle': 'Add Course',
                'link': '/courses/add'
            },
            {
                'subtitle': 'List Courses',
                'link': '/courses/list'
            }
        ]
    },
    {
        'title': 'Categories',
        'link': '/categories',
        'icon':'micon bi bi-command',
        'sublinks': [
            {
                'subtitle': 'Categories',
                'link': '/categories'
            }, 
            {
                'subtitle': 'Subcategories',
                'link': '/subcategories'
            }
        ]
    },
    {
        'title': 'Applicants',
        'link': '/applicants',
        'icon':'micon bi bi-list',
        'sublinks': [
            {
                'subtitle': 'List Applicants',
                'link': '/applicants'
            }
        ]
    }
];
