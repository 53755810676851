import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { maxios } from '../store/maxios'; // Adjust according to your setup
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

const CourseEdit = () => {
    
    const { id } = useParams();
    
    const courseId =id;  
   
    
    const [courseName, setCourseName] = useState('');
    const dispatch = useDispatch();
    const data = useSelector(state => state.auth.data);
    const [courseDescription, setCourseDescription] = useState('');
    const [courseOverview, setCourseOverview] = useState('');
    const [courseDuration, setCourseDuration] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [instructorName, setInstructorName] = useState('');
    const [courseLevel, setCourseLevel] = useState('');
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [certificationAvailable, setCertificationAvailable] = useState(false);
    const [eligibilityRequirements, setEligibilityRequirements] = useState('');
    const [benefits, setBenefits] = useState('');
    const [image, setImage] = useState(null); 
    const [imagePreview, setImagePreview] = useState('');
    const [message, setMessage] = useState('');
    const [validationError, setValidationError] = useState({});

    const [categoryList, setCategoryList] = useState([]);
    const [subcategoryList, setSubcategoryList] = useState([]);

    const selectedCategory = (e) => {
        setCategory(e);
        const sclist = data.subcategories.filter(sc => sc.course_category_id == e);
        setSubcategory(sclist.length > 0 ? sclist[0].id : '');
        setSubcategoryList(sclist);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            const reader = new FileReader();
            reader.onload = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setValidationError({});
        setMessage('');

        const courseData = new FormData();
        courseData.append('course_name', courseName);
        courseData.append('course_description', courseDescription);
        courseData.append('course_overview', courseOverview);
        courseData.append('course_duration', courseDuration);
        courseData.append('start_date', startDate);
        courseData.append('end_date', endDate);
        courseData.append('instructor_name', instructorName);
        courseData.append('course_level', courseLevel);
        courseData.append('category', category);
        courseData.append('subcategory', subcategory);
        courseData.append('certification_available', certificationAvailable ? 1 : 0);
        courseData.append('eligibility_requirements', eligibilityRequirements);
        courseData.append('benefits', benefits);
        if (image) {
            courseData.append('image', image);
        }

        try {
            const response = await maxios.post(courseId ? `/courses/edit/${courseId}` : '/courses/add', courseData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.response) {
                setMessage(response.data.message);
                // Reset form fields after success
                resetForm();
                loadData(); // Reload data if necessary
            } else {
                handleValidationErrors(response);
            }
        } catch (error) {
            setMessage('Error saving course. Please try again.');
        }
    };

    const handleValidationErrors = (response) => {
        if (response.data.has_error) {
            try {
                const error_list = JSON.parse(response.data.message);
                setValidationError(error_list);
                setMessage("Please solve the errors");
            } catch (e) {
                setMessage('Failed to parse response in JSON');
            }
        } else {
            setMessage(response.data.message);
        }
    };

    const resetForm = () => {
        setCourseName('');
        setCourseDescription('');
        setCourseOverview('');
        setCourseDuration('');
        setStartDate('');
        setEndDate('');
        setInstructorName('');
        setCourseLevel('');
        setCategory('');
        setSubcategory('');
        setCertificationAvailable(false);
        setEligibilityRequirements('');
        setBenefits('');
        setImage(null);
        setImagePreview('');
    };
    
    
                
    const loadCourseData = async () => {
        if (courseId) {
            // try {
               // Assuming your API returns the course data
                  if(data.courses.length>0){
                  for (var i=0; i<data.courses.length;i++){
                    
                    if(data.courses[i].id==courseId){
                        const course=data.courses[i];
                        setCourseName(course.course_name);
                        setCourseDescription(course.course_description);
                        setCourseOverview(course.course_overview);
                        setCourseDuration(course.course_duration);
                        setStartDate(course.start_date);
                        setEndDate(course.end_date);
                        setInstructorName(course.instructor_name);
                        setCourseLevel(course.course_level);
                        setCategory(course.category);
                        setSubcategory(course.subcategory);
                        setCertificationAvailable(course.certification_available);
                        setEligibilityRequirements(course.eligibility_requirements);
                        setBenefits(course.benefits);
                        setImagePreview(course.image);
                    }
                  }
                     }
                 // Assuming your API returns the full image URL
            // } catch (error) {
            //     setMessage('Error loading course data.');
            // }
        }
    };

    const setCatList = () => {
        if (data.categories.length > 0) {
            setCategoryList(data.categories);
            setSubcategoryList(data.subcategories);
        }
    };

    const loadData = async () => {
        try {
            const response = await maxios.get('/get-data');
            dispatch({ type: 'SET_DATA', payload: response.data });
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        loadData();
        setCatList();
        loadCourseData(); // Load course data if editing

        // Cleanup function to clear the timeout if the component unmounts
        return () => {};
    }, [courseId]);

    return (
        <div className="main-container">
            <div className="xs-pd-20-10 pd-ltr-20">
                <div className="title pb-20">
                    <h2 className="h3 mb-0">{courseId ? 'Edit Course' : 'Add Course'}</h2>
                    <p style={{ color: 'orangered', fontWeight: 'normal', fontSize: '16px' }}>{message}</p>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Course Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={courseName}
                                    onChange={(e) => setCourseName(e.target.value)}
                                />
                                <span className='error error-text'>{validationError.course_name}</span>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Course Duration</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={courseDuration}
                                    onChange={(e) => setCourseDuration(e.target.value)}
                                />
                                <span className='error error-text'>{validationError.course_duration}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <span className='error error-text'>{validationError.start_date}</span>
                            </div>
                        </div>
                     
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>End Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                                <span className='error error-text'>{validationError.end_date}</span>
                            </div>
                        </div>
                        
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Instructor Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={instructorName}
                                    onChange={(e) => setInstructorName(e.target.value)}
                                />
                                <span className='error error-text'>{validationError.instructor_name}</span>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Course Level</label>
                                <select
                                    type="text"
                                    className="form-control"
                                    value={courseLevel}
                                    onChange={(e) => setCourseLevel(e.target.value)}
                                >
                                    <option value={0}>
                                         Beginner
                                    </option>
                                    <option value={1}>
                                         Intermediate
                                    </option>
                                    <option value={2}>
                                         Advanced
                                    </option>
                                </select>
                                <span className='error error-text'>{validationError.course_level}</span>
                            </div>
                        </div>


                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Course Description</label>
                                <textarea
                                    className="form-control"
                                    value={courseDescription}
                                    onChange={(e) => setCourseDescription(e.target.value)}
                                />
                                <span className='error error-text'>{validationError.course_description}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Course Overview</label>
                                <textarea
                                    className="form-control"
                                    value={courseOverview}
                                    onChange={(e) => setCourseOverview(e.target.value)}
                                />
                                <span className='error error-text'>{validationError.course_overview}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Category</label>
                                <select
                                    className="form-control"
                                    value={category}
                                    onChange={(e) => selectedCategory(e.target.value)}
                                >
                                    <option>Select</option>
                                    {categoryList.map((catl) => (
                                        <option key={catl.id} value={catl.id}>{catl.name}</option>
                                    ))}
                                </select>
                                <span className='error error-text'>{validationError.category}</span>
                            </div>
                         
                            <div className="form-group">
                                <label>Subcategory</label>
                                <select
                                    className="form-control"
                                    value={subcategory}
                                    onChange={(e) => setSubcategory(e.target.value)}
                                >
                                    <option>Select</option>
                                    {subcategoryList.map((catl) => (
                                        <option key={catl.id} value={catl.id}>{catl.name}</option>
                                    ))}
                                </select>
                                <span className='error error-text'>{validationError.subcategory}</span>
                            </div>
                            <div className="form-group">
                                <label>Certification Available</label>
                                <input
                                    type="checkbox"
                                    checked={certificationAvailable}
                                    onChange={(e) => setCertificationAvailable(e.target.checked)}
                                />
                            </div>

                            
                        </div>
                        

                        
                    </div>

                     

                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Eligibility Requirements</label>
                                <textarea
                                    className="form-control"
                                    value={eligibilityRequirements}
                                    onChange={(e) => setEligibilityRequirements(e.target.value)}
                                />
                                <span className='error error-text'>{validationError.eligibility_requirements}</span>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Benefits</label>
                                <textarea
                                    className="form-control"
                                    value={benefits}
                                    onChange={(e) => setBenefits(e.target.value)}
                                />
                                <span className='error error-text'>{validationError.benefits}</span>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Course Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                                {imagePreview && (
                                    <img src={imagePreview} alt="Preview" style={{ width: '100%', marginTop: '10px' }} />
                                )}
                                <span className='error error-text'>{validationError.image}</span>
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary">Add Course</button>
                </form>
            </div>
        </div>
    );
};

export default CourseEdit;
