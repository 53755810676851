import React from 'react'
import { useSelector } from 'react-redux'

function Home() {
const data = useSelector(state=>state.auth.data);
  return (
    <div className="main-container">
			<div className="xs-pd-20-10 pd-ltr-20">
				<div className="title pb-20">
					<h2 className="h3 mb-0">Courses Overview</h2>
				</div>

				<div className="row pb-10">
					<div className="col-xl-4 col-lg-4 col-md-6 mb-20">
						<div className="card-box height-100-p widget-style3">
							<div className="d-flex flex-wrap">
								<div className="widget-data">
									<div className="weight-700 font-24 text-dark">0</div>
									<div className="font-14 text-secondary weight-500">
										Applicants
									</div>
								</div>
								<div className="widget-icon">
									<div className="icon" data-color="#00eccf">
										<i className="icon-copy dw dw-calendar"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-lg-4 col-md-6 mb-20">
						<div className="card-box height-100-p widget-style3">
							<div className="d-flex flex-wrap">
								<div className="widget-data">
									<div className="weight-700 font-24 text-dark">{data.courses.length}</div>
									<div className="font-14 text-secondary weight-500">
										Total Courses
									</div>
								</div>
								<div className="widget-icon">
									<div className="icon" data-color="#ff5b5b">
										<span className="icon-copy ti-calendar"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-lg-4 col-md-6 mb-20">
						<div className="card-box height-100-p widget-style3">
							<div className="d-flex flex-wrap">
								<div className="widget-data">
									<div className="weight-700 font-24 text-dark">{data.categories.length}</div>
									<div className="font-14 text-secondary weight-500">
										Total Categories
									</div>
								</div>
								<div className="widget-icon">
									<div className="icon">
										<i
											className="icon-copy fa fa-user"
											aria-hidden="true"
										></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					 
				</div>

				<div className="row pb-10">
					<div className="col-md-8 mb-20">
						<div className="card-box height-100-p pd-20">
							<div
								className="d-flex flex-wrap justify-content-between align-items-center pb-0 pb-md-3"
							>
								<div className="h5 mb-md-0">Application Activities</div>
								<div className="form-group mb-md-0">
									<select className="form-control form-control-sm selectpicker">
										<option value="">Last Week</option>
										<option value="">Last Month</option>
										<option value="">Last 6 Month</option>
										<option value="">Last 1 year</option>
									</select>
								</div>
							</div>
							<div id="activities-chart"></div>
						</div>
					</div>
					<div className="col-md-4 mb-20">
						<div
							className="card-box min-height-200px pd-20 mb-20"
							data-bgcolor="#455a64"
						>
							<div className="d-flex justify-content-between pb-20 text-white">
								<div className="icon h1 text-white">
									<i className="fa fa-calendar" aria-hidden="true"></i> 								</div>
								<div className="font-14 text-right">
									<div><i className="icon-copy ion-arrow-up-c"></i> 0%</div>
									<div className="font-12">Since last month</div>
								</div>
							</div>
							<div className="d-flex justify-content-between align-items-end">
								<div className="text-white">
									<div className="font-14">Applicants</div>
									<div className="font-24 weight-500">0</div>
								</div>
								<div className="max-width-150">
									<div id="appointment-chart"></div>
								</div>
							</div>
						</div>
						<div className="card-box min-height-200px pd-20" data-bgcolor="#265ed7">
							<div className="d-flex justify-content-between pb-20 text-white">
								<div className="icon h1 text-white">
									<i className="fa fa-user" aria-hidden="true"></i>
								</div>
								<div className="font-14 text-right">
									<div><i className="icon-copy ion-arrow-down-c"></i> 0%</div>
									<div className="font-12">Since last month</div>
								</div>
							</div>
							<div className="d-flex justify-content-between align-items-end">
								<div className="text-white">
									<div className="font-14">Signins</div>
									<div className="font-24 weight-500">0</div>
								</div>
								<div className="max-width-150">
									<div id="surgery-chart"></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				 

				<div className="footer-wrap pd-20 mb-20 card-box">
					Ace Robotics Admin
				</div>
			</div>
		</div> 
  )
}

export default Home