import React from 'react';
import { Link } from 'react-router-dom';
import { maxios } from '../store/maxios';

function CourseCard(props) {
    const { courseData, onDelete } = props; // Get onDelete prop to notify parent component
    const deleteCourse = async () => {
        try {
            const response = await maxios.get(`/courses/delete/${courseData.id}`); // Adjust the endpoint as necessary
            if (response.data.response) {
                window.location.reload()
            } else {
                alert('Failed to delete course. Please try again.');
            }
        } catch (error) {
            console.error('Error deleting course:', error);
            alert('An error occurred while deleting the course. Please try again.');
        }
    };

    return (
        <div className="col-sm-12 col-md-12 col-lg-4 mb-30">
            <div className="card card-box">
                <img className="card-img-top" src={courseData.image} style={{ height: "200px", width: '100%' }} alt={courseData.course_name} />
                <div className="card-body">
                    <h5 className="card-title weight-500">{courseData.course_name}</h5>
                    <p className="card-text">
                        {courseData.course_description}
                    </p>
                    <p className="card-text">
                        <small className="text-muted">Last updated {courseData.updated_at}</small>
                    </p>
                    <p>
                        <Link to={`/courses/edit/${courseData.id}`}>Edit</Link>
                        <button className='btn btn-default' onClick={deleteCourse} type='button'>
                            Delete
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default CourseCard;
